.homecoinlist-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  margin-top: 15px;
}

.homecoinlist-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  line-height: 50px;
  background-color: rgb(255, 255, 255);
  border-bottom: none;
}

.homecoinlist-3 {
  -webkit-tap-highlight-color: transparent;
  width: 50%;
  text-align: center;
  font-size: 14px;
  color: var(--boutton-background-color);
  border-bottom: 2px solid var(--boutton-background-color);
  margin: 0px 10px;
  font-weight: 700;
}

.homecoinlist-4 {
  -webkit-tap-highlight-color: transparent;
  width: 50%;
  text-align: center;
  font-size: 14px;
  color: rgb(119, 119, 119);
  margin: 0px 10px;
  font-weight: 700;
}

.homecoinlist-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  min-height: 156px;
}

.homecoinlist-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-11 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-13 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-14 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-20 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-22 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-23 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-29 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-31 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-32 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-37 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-38 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-40 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-41 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-43 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-44 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-46 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-47 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-49 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-50 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-51 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-52 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-53 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-54 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-55 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-56 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-57 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-58 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-59 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-60 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-61 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-62 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-63 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-64 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-65 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-66 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-67 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-68 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-69 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-70 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-71 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-72 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-73 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-74 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-75 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-76 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-77 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-78 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-79 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-80 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-81 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-82 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-83 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-84 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-85 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-86 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-87 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-88 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-89 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-90 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-91 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-92 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-93 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-94 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-95 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-96 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-97 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-98 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-99 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-100 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-101 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-102 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-103 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-104 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-105 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-106 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-107 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-108 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-109 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-110 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-111 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-112 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-113 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-114 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-115 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-116 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-117 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-118 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-119 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-120 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-121 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-122 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-123 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-124 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-125 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-126 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-127 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-128 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-129 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-130 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-131 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-132 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-133 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-134 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-135 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-136 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-137 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-138 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-139 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-140 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-141 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-142 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-143 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-144 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-145 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-146 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-147 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-148 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-149 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-150 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-151 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-152 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-153 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-154 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-155 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-156 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-157 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-158 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-159 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-160 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-161 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-162 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-163 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-164 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-165 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-166 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-167 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-168 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-169 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-170 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-171 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-172 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-173 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-174 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-175 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-176 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-177 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-178 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-179 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-180 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-181 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-182 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-183 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-184 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-185 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-186 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-187 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-188 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-189 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-190 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-191 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-192 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-193 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-194 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-195 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-196 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-197 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-198 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-199 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-200 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-201 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-202 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-203 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-204 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-205 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-206 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-207 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-208 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-209 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-210 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-211 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-212 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-213 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-214 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-215 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-216 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-217 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-218 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-219 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-220 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-221 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-222 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-223 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-224 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-225 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-226 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-227 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-228 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-229 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-230 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-231 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-232 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-233 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-234 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-235 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-236 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-237 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-238 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-239 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-240 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-241 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-242 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-243 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-244 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-245 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-246 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-247 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-248 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-249 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-250 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-251 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-252 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-253 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-254 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-255 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-256 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-257 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-258 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-259 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-260 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-261 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-262 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-263 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-264 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-265 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-266 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-267 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-268 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-269 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-270 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-271 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-272 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-273 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-274 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-275 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-276 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-277 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-278 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-279 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-280 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-281 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-282 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-283 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-284 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-285 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-286 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-287 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-288 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-289 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-290 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-291 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-292 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-293 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-294 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-295 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-296 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-297 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-298 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-299 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-300 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-301 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-302 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-303 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-304 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-305 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-306 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-307 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-308 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-309 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-310 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-311 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-312 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-313 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-314 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-315 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-316 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-317 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-318 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-319 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-320 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-321 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-322 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-323 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-324 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-325 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-326 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-327 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-328 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-329 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-330 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-331 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-332 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-333 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-334 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-335 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-336 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-337 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-338 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-339 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-340 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-341 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-342 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-343 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-344 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-345 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-346 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-347 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-348 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-349 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-350 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-351 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-352 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-353 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-354 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-355 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-356 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-357 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-358 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-359 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-360 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-361 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-362 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-363 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-364 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-365 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-366 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-367 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-368 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-369 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-370 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-371 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-372 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-373 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-374 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}

.homecoinlist-375 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.homecoinlist-376 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.homecoinlist-377 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 15px;
}

.homecoinlist-378 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homecoinlist-379 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.homecoinlist-380 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  width: 156px;
  font-weight: 700;
  font-size: 16px;
}

.homecoinlist-381 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.homecoinlist-382 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.homecoinlist-383 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101);
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 78px !important;
}


.jiaoyiliang-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  min-height: 150px;
}

.jiaoyiliang-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-7 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-9 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-10 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-16 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-18 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-19 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-25 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-27 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-28 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-34 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-36 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-37 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-40 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-41 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-43 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-44 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-45 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-46 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-51 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-52 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-53 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-54 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-55 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-56 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-57 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-58 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-59 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-60 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-61 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-62 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-63 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-64 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-65 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-66 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-67 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-68 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-69 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-70 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-71 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-72 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-73 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-74 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-75 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-76 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-77 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-78 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-79 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-80 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-81 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-82 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-83 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-84 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-85 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-86 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-87 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-88 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-89 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-90 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-91 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-92 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-93 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-94 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-95 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-96 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-97 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-98 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-99 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-100 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-101 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-102 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-103 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-104 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-105 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-106 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-107 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-108 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-109 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-110 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-111 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-112 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-113 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-114 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-115 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-116 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-117 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-118 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-119 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-120 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-121 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-122 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-123 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-124 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-125 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-126 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-127 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-128 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-129 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-130 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-131 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-132 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-133 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-134 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-135 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-136 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-137 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-138 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-139 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-140 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-141 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-142 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-143 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-144 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-145 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-146 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-147 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-148 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-149 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-150 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-151 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-152 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-153 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-154 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-155 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-156 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-157 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-158 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-159 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-160 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-161 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-162 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-163 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-164 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-165 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-166 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-167 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-168 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-169 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-170 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-171 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-172 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-173 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-174 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-175 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-176 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-177 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-178 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-179 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-180 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-181 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-182 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-183 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-184 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-185 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-186 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-187 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-188 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-189 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-190 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-191 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-192 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-193 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-194 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-195 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-196 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-197 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-198 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-199 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-200 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-201 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-202 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-203 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-204 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-205 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-206 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-207 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-208 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-209 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-210 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-211 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-212 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-213 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-214 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-215 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-216 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-217 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-218 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-219 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-220 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-221 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-222 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-223 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-224 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-225 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-226 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-227 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-228 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-229 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-230 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-231 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-232 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-233 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-234 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-235 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-236 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-237 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-238 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-239 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-240 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-241 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-242 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-243 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-244 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-245 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-246 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-247 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-248 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-249 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-250 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-251 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-252 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-253 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-254 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-255 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-256 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-257 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-258 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-259 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-260 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-261 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-262 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-263 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-264 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-265 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-266 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-267 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-268 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-269 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-270 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-271 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-272 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-273 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-274 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-275 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-276 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-277 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-278 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-279 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-280 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-281 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-282 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-283 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-284 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-285 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-286 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-287 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-288 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-289 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-290 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-291 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-292 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-293 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-294 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-295 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-296 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-297 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-298 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-299 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-300 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-301 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-302 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-303 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-304 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-305 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-306 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-307 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-308 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-309 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-310 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-311 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-312 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-313 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-314 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-315 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-316 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-317 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-318 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-319 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-320 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-321 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-322 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-323 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-324 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-325 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-326 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-327 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-328 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-329 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-330 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-331 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-332 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-333 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-334 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-335 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-336 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-337 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-338 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-339 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-340 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-341 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-342 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-343 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-344 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-345 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-346 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-347 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-348 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-349 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-350 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-351 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-352 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-353 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-354 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-355 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-356 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-357 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-358 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-359 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-360 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-361 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-362 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-363 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-364 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-365 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-366 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-367 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-368 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-369 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-370 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}

.jiaoyiliang-371 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.jiaoyiliang-372 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px;
}

.jiaoyiliang-373 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.jiaoyiliang-374 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaoyiliang-375 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.jiaoyiliang-376 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 700;
  font-size: 16px;
  width: 120px;
}

.jiaoyiliang-377 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.jiaoyiliang-378 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  font-weight: 700;
}

.jiaoyiliang-379 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 8px 10px;
  color: rgb(255, 255, 255);
  background: rgb(0, 171, 255) !important;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  width: 90px !important;
}