.quotationcenter-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  color: rgb(48, 49, 51);
  font-size: 14px;
}

.quotationcenter-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.quotationcenter-2:before {
  content: "";
  left: 0px;
  right: 0px;
  height: 1px;
  transform: scaleY(0.5);
  background-color: rgb(200, 199, 204);
  position: fixed;
  top: 44px;
  z-index: 3001;
}

.quotationcenter-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  background: rgb(255, 255, 255);
  width: 100%;
}

.quotationcenter-4 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: space-around;
}

.quotationcenter-5 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  border-bottom: 3px solid rgb(255, 255, 255);
}

.quotationcenter-6 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  color: var(--boutton-background-color);
  text-align: center;
  line-height: 40px;
  font-size: 16px !important;
  border-bottom: 3px solid var(--boutton-background-color) !important;
}

.quotationcenter-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  color: rgb(255, 255, 255);
  height: 100vh;
}

.quotationcenter-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateZ(0px);
}

.quotationcenter-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
}

.quotationcenter-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  will-change: transform;
  width: 100%;
  height: 100%;
  transform: translate(-100%, 0px) translateZ(0px);
}

.quotationcenter-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  will-change: transform;
  cursor: grab;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(0%, 0px) translateZ(0px);
}

.quotationcenter-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  height: 100vh;
}

.quotationcenter-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
}

.quotationcenter-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
}

.quotationcenter-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
}

.quotationcenter-16 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 10px;
}

.quotationcenter-17 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-19 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-26 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  will-change: transform;
  cursor: grab;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(100%, 0px) translateZ(0px);
}

.quotationcenter-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  height: 100vh;
}

.quotationcenter-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
}

.quotationcenter-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
}

.quotationcenter-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
}

.quotationcenter-36 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 10px;
  padding-bottom: 120px;
}

.quotationcenter-37 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-39 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-40 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-41 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-43 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-44 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-46 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-51 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-52 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-53 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-54 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-55 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-56 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-57 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-58 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-59 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-60 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-61 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-62 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-63 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-64 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-65 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-66 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-67 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-68 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-69 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-70 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-71 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-72 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-73 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-74 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-75 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-76 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-77 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-78 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-79 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-80 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-81 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-82 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-83 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-84 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-85 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-86 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-87 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-88 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-89 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-90 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-91 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-92 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-93 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-94 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-95 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-96 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-97 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-98 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-99 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-100 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-101 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-102 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-103 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-104 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-105 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-106 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-107 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-108 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-109 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-110 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-111 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-112 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-113 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-114 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-115 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-116 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-117 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-118 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-119 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-120 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-121 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-122 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-123 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-124 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-125 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-126 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-127 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-128 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-129 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-130 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-131 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-132 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-133 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-134 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-135 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-136 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-137 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-138 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-139 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-140 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-141 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-142 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-143 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-144 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-145 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-146 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-147 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-148 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-149 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-150 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-151 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-152 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-153 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-154 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-155 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-156 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-157 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-158 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-159 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-160 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-161 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-162 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-163 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-164 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-165 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-166 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-167 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-168 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-169 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-170 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-171 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-172 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-173 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-174 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-175 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-176 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-177 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-178 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-179 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-180 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-181 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-182 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-183 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-184 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-185 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-186 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-187 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-188 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-189 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-190 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-191 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-192 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-193 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-194 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-195 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-196 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-197 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-198 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-199 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-200 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-201 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-202 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-203 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-204 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-205 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-206 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-207 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-208 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-209 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-210 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-211 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-212 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-213 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-214 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-215 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-216 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-217 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-218 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-219 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-220 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-221 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-222 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-223 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-224 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-225 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-226 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-227 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-228 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-229 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-230 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-231 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-232 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-233 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-234 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-235 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-236 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-237 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-238 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-239 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-240 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-241 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-242 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-243 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-244 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-245 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-246 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-247 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-248 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-249 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-250 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-251 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-252 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-253 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-254 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-255 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-256 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-257 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-258 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-259 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-260 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-261 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-262 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-263 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-264 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-265 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-266 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-267 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-268 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-269 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-270 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-271 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-272 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-273 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-274 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-275 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-276 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-277 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-278 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-279 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-280 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-281 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-282 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-283 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-284 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-285 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-286 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-287 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-288 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-289 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-290 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-291 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-292 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-293 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-294 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-295 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-296 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-297 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-298 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-299 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-300 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-301 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-302 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-303 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-304 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-305 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-306 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-307 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-308 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-309 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-310 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-311 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-312 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-313 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-314 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-315 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-316 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-317 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-318 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-319 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-320 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-321 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-322 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-323 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-324 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-325 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-326 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-327 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-328 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-329 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-330 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-331 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-332 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-333 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-334 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-335 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-336 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-337 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-338 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-339 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-340 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-341 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-342 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-343 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-344 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-345 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-346 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-347 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-348 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-349 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-350 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-351 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-352 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-353 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-354 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-355 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-356 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-357 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-358 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-359 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-360 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-361 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-362 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-363 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-364 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-365 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-366 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-367 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-368 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-369 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-370 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-371 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-372 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-373 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-374 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-375 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-376 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-377 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-378 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-379 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-380 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-381 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-382 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-383 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-384 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-385 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-386 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-387 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-388 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-389 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-390 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-391 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-392 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-393 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-394 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-395 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-396 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-397 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-398 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-399 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-400 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-401 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-402 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-403 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-404 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-405 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-406 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-407 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-408 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-409 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-410 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-411 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-412 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-413 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-414 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-415 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-416 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-417 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-418 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-419 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-420 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-421 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-422 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-423 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-424 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-425 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-426 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-427 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-428 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-429 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-430 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-431 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-432 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-433 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
 
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-434 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-435 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-436 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-437 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-438 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-439 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-440 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-441 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-442 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-443 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-444 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-445 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-446 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-447 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-448 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-449 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-450 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-451 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-452 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-453 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-454 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-455 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-456 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-457 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-458 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-459 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-460 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-461 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-462 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-463 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-464 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-465 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-466 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-467 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-468 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-469 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-470 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-471 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-472 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-473 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-474 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-475 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
 
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-476 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-477 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-478 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-479 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-480 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-481 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-482 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-483 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-484 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-485 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-486 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-487 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-488 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-489 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-490 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-491 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-492 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-493 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-494 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-495 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-496 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-497 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-498 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-499 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-500 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-501 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-502 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-503 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-504 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-505 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-506 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-507 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-508 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-509 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-510 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-511 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-512 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-513 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-514 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-515 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-516 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-517 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
 
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-518 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-519 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-520 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-521 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-522 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-523 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-524 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-525 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-526 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-527 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-528 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-529 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-530 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-531 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
 
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-532 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-533 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-534 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-535 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-536 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-537 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-538 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-539 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-540 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-541 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-542 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-543 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-544 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-545 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-546 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-547 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-548 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-549 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-550 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-551 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-552 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-553 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-554 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-555 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-556 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-557 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-558 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-559 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
 
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-560 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-561 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-562 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-563 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-564 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-565 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-566 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-567 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-568 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-569 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-570 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-571 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-572 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-573 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
 
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-574 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-575 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-576 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-577 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-578 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-579 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-580 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.quotationcenter-581 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-582 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(28, 173, 144) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-583 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-584 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-585 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-586 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-587 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-588 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-589 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-590 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-591 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-592 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-593 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-594 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-595 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-596 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-597 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-598 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-599 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-600 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-601 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
 
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-602 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-603 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-604 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-605 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-606 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-607 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-608 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-609 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-610 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}

.quotationcenter-611 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 15px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px !important;
}

.quotationcenter-612 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.quotationcenter-613 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px 5px 0px 0px;
}

.quotationcenter-614 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.quotationcenter-615 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
 
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.quotationcenter-616 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;

}

.quotationcenter-617 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-618 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.quotationcenter-619 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.quotationcenter-620 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 12px;
  color: rgb(119, 119, 119);
}

.quotationcenter-621 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.quotationcenter-622 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.quotationcenter-623 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.quotationcenter-624 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  width: 72px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  background: rgb(205, 78, 101) !important;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
}