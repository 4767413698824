.propertycenter-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
}

.propertycenter-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.propertycenter-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(255, 255, 255);
  height: 170px;
  /* background-image: url(/public/ICON/25.png); */
  background-image: url(/public/new/3381736834929_.pic.jpg);

  background-size: 100% 100%;
}

.propertycenter-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 390px;
  height: 0px;
}

.propertycenter-5 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 14px;
}

.propertycenter-6 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 26px;
}

.propertycenter-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 20px;
  padding-left: 4px;
}

.propertycenter-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 5px 0px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.propertycenter-9 {
  -webkit-tap-highlight-color: transparent;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  margin-top: 11px;
  margin-bottom: 11px;
}

.propertycenter-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  height: auto;
  width: auto;
  display: block;
  cursor: pointer;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  text-align: center;
  font-size: 14px;
  padding: 20px 0px;
}

.propertycenter-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 46px;
  height: 44px;
}

.propertycenter-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-image: url(/public/ICON/20.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.propertycenter-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.propertycenter-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
}

.propertycenter-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.propertycenter-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.propertycenter-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.propertycenter-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.propertycenter-19 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.propertycenter-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  font-weight: 700;
}

.propertycenter-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  height: auto;
  width: auto;
  display: block;
  cursor: pointer;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  text-align: center;
  font-size: 14px;
  padding: 20px 0px;
}

.propertycenter-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 46px;
  height: 44px;
}

.propertycenter-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-image: url(/public/ICON/21.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.propertycenter-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.propertycenter-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
}

.propertycenter-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.propertycenter-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.propertycenter-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.propertycenter-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.propertycenter-30 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.propertycenter-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  font-weight: 700;
}

.propertycenter-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  text-align: center;
  font-size: 14px;
  padding: 20px 0px;
}

.propertycenter-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 46px;
  height: 44px;
}

.propertycenter-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-image: url(/public/ICON/22.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.propertycenter-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.propertycenter-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
}

.propertycenter-37 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.propertycenter-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.propertycenter-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.propertycenter-40 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.propertycenter-41 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.propertycenter-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  font-weight: 700;
}

.propertycenter-43 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.propertycenter-44 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  background: rgb(255, 255, 255);
  margin-bottom: 20px;
}

.propertycenter-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.propertycenter-46 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  position: relative;
}

.propertycenter-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
}

.propertycenter-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
  overflow: auto hidden;
}

.propertycenter-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
}

.propertycenter-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  position: relative;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.propertycenter-51 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  text-align: center;
  transition-property: background-color, color;
  height: 41px;
  line-height: 41px;
  font-size: 15px;
  transition-duration: 0.5s;
  flex: 1 1 0%;
  width: auto;
  color: var(--boutton-background-color);
  font-weight: bold;
}

.propertycenter-52 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  text-align: center;
  transition-property: background-color, color;
  height: 41px;
  line-height: 41px;
  font-size: 15px;
  transition-duration: 0.5s;
  flex: 1 1 0%;
  width: auto;
  color: rgb(48, 49, 51);
}

.propertycenter-53 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  position: absolute;
  bottom: 0px;
  width: 30px;
  transform: translate(calc((100vw - 30px) / 2));
  transition-duration: 0.5s;
  background-color: var(--boutton-background-color);
  height: 3px;
  border-radius: 3px;
}

.propertycenter-54 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-55 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-56 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-57 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-58 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-59 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-60 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-61 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-62 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-63 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-64 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-65 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-66 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-67 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-68 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-69 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-70 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-71 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-72 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-73 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-74 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-75 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-76 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-77 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-78 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-79 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-80 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-81 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-82 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-83 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-84 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-85 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-86 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-87 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-88 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-89 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-90 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-91 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-92 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-93 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-94 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-95 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-96 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-97 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-98 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-99 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-100 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-101 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-102 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-103 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-104 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-105 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-106 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-107 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-108 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-109 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-110 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-111 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-112 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-113 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-114 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-115 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-116 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-117 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-118 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-119 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-120 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-121 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-122 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-123 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-124 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-125 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-126 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-127 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-128 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-129 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-130 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-131 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-132 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-133 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-134 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-135 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-136 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-137 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-138 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-139 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-140 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-141 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-142 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-143 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-144 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-145 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-146 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-147 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-148 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-149 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-150 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-151 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-152 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-153 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-154 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-155 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-156 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-157 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-158 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-159 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-160 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-161 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-162 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-163 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-164 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-165 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-166 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-167 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-168 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-169 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-170 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-171 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-172 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-173 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-174 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-175 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-176 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-177 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-178 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-179 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-180 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-181 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-182 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-183 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-184 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-185 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-186 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-187 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-188 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-189 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-190 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-191 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-192 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-193 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-194 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-195 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-196 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-197 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  width: 92%;
  margin: 0px auto 10px;
  background-color: rgb(255, 255, 255);
  padding-top: 16px;
}

.propertycenter-198 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.propertycenter-199 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.propertycenter-200 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  color: var(--boutton-background-color);
}

.propertycenter-201 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 10px;
}

.propertycenter-202 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
  text-align: center;
}

.propertycenter-203 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-204 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-205 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-206 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-around;
  text-align: center;
  font-weight: 700;
}

.propertycenter-207 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-208 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}

.propertycenter-209 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 122px;
  word-break: break-word;
  padding: 0px 5px;
}
