.coinPopupTopTitle-1 {
  -webkit-tap-highlight-color: transparent;
  background: rgb(255, 255, 255);
  inset: 0px 30% 0px 0px;
  border-radius: 0px;
  margin: auto;
}

.coinPopupTopTitle-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  color: rgb(0, 0, 0);
  line-height: 50px;
  border-bottom: 1px solid rgb(230, 234, 237);
  position: relative;
  border: 0px;
  text-align: left;
  text-indent: 10px;
}

.coinPopupTopTitle-3 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-weight: 700;
  font-size: 14px;
  text-align: center !important;
  margin-left: 10px;
}

.coinPopupTopTitle-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  background: rgb(255, 255, 255);
  width: 100%;
  top: 52px;
}

.coinPopupTopTitle-5 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: space-around;
}

.coinPopupTopTitle-6 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  border-bottom: 3px solid rgb(255, 255, 255);
}

.coinPopupTopTitle-7 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  color: var(--boutton-background-color);
  text-align: center;
  line-height: 40px;
  font-size: 16px !important;
  border-bottom: 3px solid var(--boutton-background-color) !important;
}

.coinPopupTopTitle-8 {
  -webkit-tap-highlight-color: transparent;
  margin: 8px auto 0px;
  background: rgb(247, 246, 251);
  line-height: 35px;
  height: 35px;
  border-radius: 5px;
  width: 90%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-9 {
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin: 10px;
}

.coinPopupTopTitle-9:before {
  content: "";
}

.coinPopupTopTitle-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 16px;
  line-height: 1.4em;
  min-height: 1.4em;
  overflow: hidden;
  background: none;
  border: none;
  height: 35px;
}

.coinPopupTopTitle-11 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.coinPopupTopTitle-12 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: absolute;
  left: 0px;
  color: grey;
  overflow: hidden;
  text-overflow: clip;
  white-space: pre;
  word-break: keep-all;
  pointer-events: none;
  line-height: inherit;
  top: auto !important;
  font-size: 12px;
}

.coinPopupTopTitle-13 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: relative;
  display: block;
  height: 100%;
  background: none;
  color: inherit;
  opacity: 1;
  font: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-indent: inherit;
  text-transform: inherit;
  text-shadow: inherit;
}

.coinPopupTopTitle-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  padding: 10px;
  height: 661px;
}

.coinPopupTopTitle-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateZ(0px);
}

.coinPopupTopTitle-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
}

.coinPopupTopTitle-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  will-change: transform;
  width: 100%;
  height: 100%;
  transform: translate(-100%, 0px) translateZ(0px);
}

.coinPopupTopTitle-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  overflow: hidden;
  will-change: transform;
  cursor: grab;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(0%, 0px) translateZ(0px);
}

.coinPopupTopTitle-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  height: 661px;
}

.coinPopupTopTitle-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
}

.coinPopupTopTitle-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
}

.coinPopupTopTitle-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
}

.coinPopupTopTitle-23 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 10px;
}

.coinPopupTopTitle-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  overflow: hidden;
  will-change: transform;
  cursor: grab;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(100%, 0px) translateZ(0px);
}

.coinPopupTopTitle-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  height: 661px;
}

.coinPopupTopTitle-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
}

.coinPopupTopTitle-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
}

.coinPopupTopTitle-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
}

.coinPopupTopTitle-29 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 10px;
  padding-bottom: 120px;
}

.coinPopupTopTitle-30 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-37 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-40 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-41 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-43 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-44 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-46 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-51 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-52 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-53 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-54 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-55 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-56 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-57 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-58 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-59 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-60 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-61 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-62 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-63 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-64 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-65 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-66 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-67 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-68 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-69 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-70 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-71 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-72 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-73 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-74 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-75 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-76 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-77 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-78 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.coinPopupTopTitle-79 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-80 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-81 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-82 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-83 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-84 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-85 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-86 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-87 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-88 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-89 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-90 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-91 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-92 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-93 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-94 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-95 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-96 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-97 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-98 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-99 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-100 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-101 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-102 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-103 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-104 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-105 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-106 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-107 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-108 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-109 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-110 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-111 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-112 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-113 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-114 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-115 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-116 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-117 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-118 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-119 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-120 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-121 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-122 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-123 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-124 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-125 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-126 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-127 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-128 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-129 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-130 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-131 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-132 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-133 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-134 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-135 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-136 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-137 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-138 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-139 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-140 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-141 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-142 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-143 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-144 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-145 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-146 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-147 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-148 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-149 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-150 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-151 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-152 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-153 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-154 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-155 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-156 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-157 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-158 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-159 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-160 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-161 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-162 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.coinPopupTopTitle-163 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-164 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-165 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-166 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-167 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-168 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-169 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-170 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-171 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-172 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-173 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-174 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-175 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-176 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-177 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-178 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-179 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-180 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-181 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-182 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-183 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.coinPopupTopTitle-184 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-185 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-186 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-187 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-188 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-189 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-190 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.coinPopupTopTitle-191 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-192 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-193 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-194 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-195 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-196 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-197 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-198 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-199 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-200 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-201 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-202 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-203 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-204 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.coinPopupTopTitle-205 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-206 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-207 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-208 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-209 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-210 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-211 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-212 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-213 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-214 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-215 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-216 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-217 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-218 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-219 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-220 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-221 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-222 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-223 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-224 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-225 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-226 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-227 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-228 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-229 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-230 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-231 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-232 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-233 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-234 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-235 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-236 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-237 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-238 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-239 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.coinPopupTopTitle-240 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-241 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-242 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-243 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-244 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-245 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-246 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-247 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-248 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-249 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-250 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-251 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-252 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-253 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-254 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-255 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-256 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-257 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-258 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-259 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-260 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-261 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-262 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-263 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-264 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-265 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-266 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-267 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.coinPopupTopTitle-268 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-269 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-270 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-271 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-272 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-273 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-274 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.coinPopupTopTitle-275 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-276 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-277 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-278 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-279 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-280 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-281 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-282 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-283 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-284 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-285 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-286 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-287 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-288 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}

.coinPopupTopTitle-289 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-290 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-291 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-292 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-293 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-294 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-295 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-296 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-297 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-298 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-299 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-300 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-301 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-302 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-303 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-304 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-305 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-306 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-307 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-308 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-309 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(28, 173, 144) !important;
}

.coinPopupTopTitle-310 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px 0px;
}

.coinPopupTopTitle-311 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 156px;
}

.coinPopupTopTitle-312 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.coinPopupTopTitle-313 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.coinPopupTopTitle-314 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
  color: rgb(153, 153, 153);
}

.coinPopupTopTitle-315 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.coinPopupTopTitle-316 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(205, 78, 101) !important;
}