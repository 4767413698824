.idcard-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  color: rgb(48, 49, 51);
  font-size: 14px;
}

.idcard-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.idcard-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  background: rgb(255, 255, 255);
  margin-bottom: 10px;
}

.idcard-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 10px;
}

.idcard-5 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.idcard-6 {
  -webkit-tap-highlight-color: transparent;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  color: rgb(0, 0, 0);
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgb(247, 246, 251);
  font-size: 14px;
  flex-wrap: wrap;
  margin: 20px 0px;
}

.idcard-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
}

.idcard-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.idcard-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  border-radius: 2px;
  background: rgb(255, 255, 255);
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  border: 1px solid rgb(225, 225, 230);
  background-color: rgb(255, 255, 255);
  padding: 0px 10px;
  border-color: rgb(220, 223, 230);
  text-align: left;
}

.idcard-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: 1.4em;
  height: 1.4em;
  overflow: hidden;
  font-size: 14px;
  color: rgb(48, 49, 51);
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  min-height: 35px;
}

.idcard-11 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.idcard-12 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: absolute;
  left: 0px;
  overflow: hidden;
  text-overflow: clip;
  white-space: pre;
  word-break: keep-all;
  pointer-events: none;
  line-height: inherit;
  top: auto !important;
  color: rgb(192, 196, 204);
}

.idcard-13 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: relative;
  display: block;
  height: 100%;
  background: none;
  color: inherit;
  opacity: 1;
  font: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-indent: inherit;
  text-transform: inherit;
  text-shadow: inherit;
  -webkit-text-fill-color: currentcolor;
}

.idcard-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.idcard-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  margin-left: 5px;
  transition: transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
}

.idcard-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.idcard-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: uicon-iconfont;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: relative;
  font-size: 13px;
  font-weight: normal;
  top: 0px;
  color: rgb(192, 196, 204);
}

.idcard-17:before {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  content: "";
}

.idcard-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.idcard-19 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 0px;
  display: flex;
  color: rgb(0, 0, 0);
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgb(247, 246, 251);
  font-size: 14px;
  flex-wrap: wrap;
  margin: 20px 0px;
}

.idcard-20 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  width: 100%;
  margin-bottom: 10px;
}

.idcard-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 16px;
  line-height: 1.4em;
  min-height: 1.4em;
  overflow: hidden;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  border-radius: 2px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(225, 225, 230);
  background-color: rgb(255, 255, 255);
}

.idcard-22 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.idcard-23 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: absolute;
  left: 0px;
  color: grey;
  overflow: hidden;
  text-overflow: clip;
  white-space: pre;
  word-break: keep-all;
  pointer-events: none;
  line-height: inherit;
  top: auto !important;
}

.idcard-24 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: relative;
  display: block;
  height: 100%;
  background: none;
  color: inherit;
  opacity: 1;
  font: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-indent: inherit;
  text-transform: inherit;
  text-shadow: inherit;
}

.idcard-25 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  width: 100%;
  margin-bottom: 10px;
}

.idcard-26 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  display: flex;
  color: rgb(0, 0, 0);
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(247, 246, 251);
  font-size: 14px;
  padding: 0px 96px;
  flex-wrap: wrap;
  margin: 20px 0px;
  height: auto;
}

.idcard-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(248, 248, 251);
  text-align: center;
  border-radius: 10px;
  width: 182px;
  height: 128px;
  background-image: url(/public/center/1.png);
  background-size: 100% 100%;
}

.idcard-28 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 60px;
  margin: 20px 30px 0px;
}

.idcard-28-1 {
  display: flex;
  justify-content: center;
}

.idcard-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.idcard-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  max-width: 100%;
  height: 128px;
  user-select: none;
  display: block;
}

.idcard-31 {
  -webkit-tap-highlight-color: transparent;
  margin: 20px 0px 0px;
}

.idcard-32 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  display: flex;
  color: rgb(0, 0, 0);
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(247, 246, 251);
  font-size: 14px;
  padding: 0px 96px;
  flex-wrap: wrap;
  margin: 20px 0px;
  height: auto;
}

.idcard-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(248, 248, 251);
  text-align: center;
  border-radius: 10px;
  width: 182px;
  background-image: url(/public/center/1.png);
  background-size: 100% 100%;
}

.idcard-34 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  margin: 20px 30px 0px;
}

.idcard-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.idcard-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  max-width: 100%;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
}

.idcard-37 {
  -webkit-tap-highlight-color: transparent;
  margin: 20px 0px 0px;
}

.idcard-38 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  color: rgb(0, 0, 0);
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(247, 246, 251);
  font-size: 14px;
  padding: 0px 96px;
  flex-wrap: wrap;
  margin: 20px 0px;
  height: auto;
  display: flex;
}

.idcard-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(248, 248, 251);
  text-align: center;
  border-radius: 10px;
  width: 182px;
  background-image: url(/public/center/1.png);
  background-size: 100% 100%;
}

.idcard-40 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  margin: 20px 30px 0px;
}

.idcard-41 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.idcard-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  max-width: 100%;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
}

.idcard-43 {
  -webkit-tap-highlight-color: transparent;
  margin: 20px 0px 0px;
}

.idcard-44 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.idcard-45 {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  background-color: rgb(248, 248, 248);
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  margin: 20px 0px;
  height: 40px;
  line-height: 40px;
  background: var(--boutton-background-color) !important;
  border: none !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 2px;
}

.idcard-45:after {
  content: " ";
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scale(0.5);
  transform-origin: 0px 0px;
  box-sizing: border-box;
}

.idcard-46 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.idcard-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.idcard-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.idcard-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.idcard-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.idcard-51 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.idcard-52 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.idcard-53 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.idcard-54 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.idcard-55 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.idcard-56 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.idcard-57 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.idcard-58 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.idcard-59 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}