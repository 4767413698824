.orderPopup-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(0, 0, 0) !important;
  border-radius: 0px;
  /* background: rgb(30, 33, 38); */
  background: #fff;
}

.orderPopup-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  text-align: center;
  color: rgb(0, 0, 0);
  line-height: 50px;
  border-bottom: 1px solid rgb(230, 234, 237);
  position: relative;
  border: 0px;
}

.orderPopup-3 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-weight: 700;
  font-size: 14px;
  text-align: center !important;
  margin-left: 10px;
  /* color: black; */
  color: black;
}

.orderPopup-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  width: 50px;
  text-align: center;
  line-height: 50px;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 21px;
  color: black;
  margin-right: 0px;
}

.orderPopup-4:before {
  content: '';
}

.orderPopup-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  padding: 10px;
}

.orderPopup-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.orderPopup-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 10px 0px;
}

.orderPopup-8 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  /* color: black; */
  color: black;
}

.orderPopup-9 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: black;
}

.orderPopup-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.orderPopup-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 10px 0px;
}

.orderPopup-12 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: black;
}

.orderPopup-13 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: rgb(28, 173, 144) !important;
}

.orderPopup-13-1 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: rgb(205, 78, 101) !important;
}

.orderPopup-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 10px 0px;
}

.orderPopup-15 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: black;
}

.orderPopup-16 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: black;
}

.orderPopup-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.orderPopup-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.orderPopup-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
}

.orderPopup-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  max-height: inherit;
  width: 100%;
  height: 100%;
  overflow: auto hidden;
}

.orderPopup-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
}

.orderPopup-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: inline-block;
  padding: 0px 12px;
  margin-right: 15px;
  border-radius: 2px;
  line-height: 39px;
  text-align: center;
  font-size: 15px;
  background-color: var(--boutton-background-color);
  color: black;
  opacity: 1;
}

.orderPopup-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.orderPopup-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.orderPopup-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: inline-block;
  padding: 0px 12px;
  margin-right: 15px;
  /* background-color: rgb(42, 45, 53); */
  background-color: #fafafa;
  color: black;
  border-radius: 2px;
  line-height: 39px;
  text-align: center;
  font-size: 15px;
  /* color: black; */

  opacity: 0.9;
}

.orderPopup-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.orderPopup-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.orderPopup-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: inline-block;
  padding: 0px 12px;
  margin-right: 15px;
  background-color: rgb(42, 45, 53);
  border-radius: 2px;
  line-height: 39px;
  text-align: center;
  font-size: 15px;
  color: black;
  opacity: 0.5;
}

.orderPopup-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.orderPopup-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.orderPopup-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: inline-block;
  padding: 0px 12px;
  margin-right: 15px;
  background-color: rgb(42, 45, 53);
  border-radius: 2px;
  line-height: 39px;
  text-align: center;
  font-size: 15px;
  color: black;
  opacity: 0.5;
}

.orderPopup-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.orderPopup-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.orderPopup-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: inline-block;
  padding: 0px 12px;
  margin-right: 15px;
  background-color: rgb(42, 45, 53);
  border-radius: 2px;
  line-height: 39px;
  text-align: center;
  font-size: 15px;
  color: black;
  opacity: 0.5;
}

.orderPopup-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.orderPopup-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.orderPopup-37 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.orderPopup-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  height: 26px;
}

.orderPopup-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(141, 157, 188);
}

.orderPopup-40 {
  -webkit-tap-highlight-color: transparent;
  margin: 10px auto 5px;
  font-size: 13px;
  color: black;
}

.orderPopup-41 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 16px;
  min-height: 1.4em;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 38px;
  line-height: 38px;
  padding: 0px 15px;
  border-radius: 8px;
  background-color: black;
  background: rgb(238, 229, 229);
  border: 0px;
  color: black;
}

.orderPopup-42 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.orderPopup-43 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: absolute;
  left: 0px;
  color: grey;
  overflow: hidden;
  text-overflow: clip;
  white-space: pre;
  word-break: keep-all;
  pointer-events: none;
  line-height: inherit;
  top: auto !important;
  font-size: 12px;
}

.orderPopup-44 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: relative;
  display: block;
  height: 100%;
  background: none;
  color: inherit;
  opacity: 1;
  font: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-indent: inherit;
  text-transform: inherit;
  text-shadow: inherit;
}

.orderPopup-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 12px;
  line-height: 1.8;
}

.orderPopup-46 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  margin-top: 5px;
  font-weight: 700;
  color: black;
}

.orderPopup-47 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  margin-top: 5px;
  font-weight: 700;
  color: black;
}

.orderPopup-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  width: 100%;
  color: var(--boutton-background-color);
  font-size: 14px;
}

.orderPopup-49 {
  margin: 0px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  cursor: pointer;
  height: 38px;
  line-height: 38px;
  padding: 0px 10px;
  background: var(--boutton-background-color);
  color: white;
  border: none;
  font-size: 14px;
  margin-top: 36px;
  width: 100%;
  border-radius: 2px;
  background-color: rgb(46, 173, 101);
}

.orderPopup-49:after {
  content: ' ';
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scale(0.5);
  transform-origin: 0px 0px;
  box-sizing: border-box;
}
