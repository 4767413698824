.jiekuanOutDiv {
  padding: 10px 16px;
}

.jiekuanTopHeadDiv {
  margin-top: 8px;
  height: 30px;
}

.jiekuanTopHeadLeft {
  color: #232227;
  font-size: 18px;
  float: left;
}

.jiekuanTopHeadRight {
  font-size: 20px;
  float: right;
  color: #9399A4;
}

.jiekuanTopHeadTopFont {
  margin-top: 8px;
  height: 30px;
  padding: 0 6px;
  font-size: 26px;
  font-weight: 600;
}

.jiekuanInputDiv {
  padding: 0 6px;
  margin-top: 30px;
}

.jiekuanInputFontDiv {
  font-size: 14px;
  color: #91979D;
}

.jiekuanInputiconimg {
  width: 24px;
  height: 24px;
  float: left;
  margin: 0 6px 0 0;
}

.jiekuanInputBorderDiv {
  background-color: #F6F6F6;
  padding: 14px;
  border-radius: 5px;
  margin-top: 6px;
  height: 26px;
}

.jiekuanInputFont {
  float: left;
  line-height: 26px;
  min-width: 3rem;
  font-size: 16px;
  font-weight: 600;
}

.jiekuanInputTagFont {
  background-color: var(--boutton-background-color);
  font-size: 12px;
  border-radius: 3px;
  padding: 3px 3px;
  margin-left: 10px;
  line-height: 24px;
  font-weight: 600;
}

.jiekuanInputicon {
  font-size: 16px;
  float: right;
  line-height: 30px;
  margin-top: 5px;
  color: #8F97A1;
}

.jiekuanInputicon2 {
  font-size: 14px;
  color: #8F97A1;
  margin-left: 14px;
  margin-top: 5px;
  line-height: 30px;
}

.jiekuanInputcenterFont {
  color: #8f97a13f;
  font-size: 16px;
  margin-left: 30px;
}

.jiekuanInputcenterFontnum {
  font-size: 16px;
  margin-left: 10px;
}

.jiekuanInputcenterFontnumMax {
  float: right;
  font-size: 16px;
  color: var(--boutton-background-color);
}

.jiekuanzcDiv {
  color: #E4627D;
  margin-top: 8px;
}


.jiekuantsDiv {
  background-color: #FBF7EB;
  color: var(--boutton-background-color);
  border-radius: 2px;
  padding: 8px;
  margin-top: 8px;
}

.jiekuanmxleftDiv {
  color: #91979D;
  font-size: 13px;
  height: 38px;
  line-height: 38px;
}

.jiekuanmxrightDiv {
  font-size: 13px;
  height: 38px;
  line-height: 38px;
  float: right;
}

.jiekuanbottomDiv {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 160px;
  background-color: white;
  border-top: 1px solid #8f97a13f;
  padding: 10px 18px;
}

.jiekuanbottomCenterDiv {}

.jiekuanbottomCenteragreeDiv {
  font-size: 12px;
  margin-left: 36px;
}

.jiekuanbottomCenteragreexyDiv {
  margin-left: 36px;
  margin-top: 12px;
  font-size: 12px;
  color: var(--boutton-background-color);
}

.jiekuanbottomCenteragreeqrDiv {
  border-radius: 5px;
  margin-top: 12px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
  background-color: var(--boutton-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}


.jiekuandx-9 {
  position: absolute;
  top: 10px;
  margin-left: 4px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 18px;
  vertical-align: baseline;
  background: transparent;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  height: 1em;
  line-height: 1em;
  cursor: pointer;
}

.jiekuandx-10:focus {
  outline: 0px;
  border-color: rgb(240, 184, 45);
}

.jiekuandx-10 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 1px solid rgb(200, 201, 204);
  outline: 0px;
  font-size: 0.8em;
  vertical-align: baseline;
  background: transparent;
  position: relative;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.25;
  font-family: vant-icon;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  display: block;
  width: 1.25em;
  height: 1.25em;
  color: rgb(255, 255, 255);
  text-align: center;
  transition-duration: 0.2s;
  transition-property: color, border-color, background-color;
  border-radius: 100%;
  background-color: rgb(240, 184, 45);
  border-color: rgb(240, 184, 45);
}

.jiekuandx-10:before {
  display: inline-block;
  content: "";
  border-color: rgb(240, 184, 45);
}


.jiekuandx-14 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 1px solid rgb(200, 201, 204);
  outline: 0px;
  font-size: 0.8em;
  vertical-align: baseline;
  background: transparent;
  position: relative;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.25;
  font-family: vant-icon;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  display: block;
  width: 1.25em;
  height: 1.25em;
  color: transparent;
  text-align: center;
  transition-duration: 0.2s;
  transition-property: color, border-color, background-color;
  border-radius: 100%;
}

.jiekuandx-14:before {
  display: inline-block;
  content: "";
}

.jiekuanInput{
  width: 150px;
  background-color: transparent;
  border: none;
}

.invite{
  font-size: 40px;
  color:var(--boutton-background-color);
  padding:20px 40px;
  text-align: center;
}