.optionbox3-1 {
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 95%;
  position: relative;
  height: 93px;
  margin: auto;
  border-radius: 10px;
  overflow: hidden;
}

.optionbox3-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.optionbox3-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.optionbox3-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  
}
