.bottomBar-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  z-index: 998;
  position: fixed;
  left: 0px;
  right: 0px;
}

.bottomBar-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  z-index: 998;
  box-sizing: border-box;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: rgb(255, 255, 255);
  backdrop-filter: none;
}

.bottomBar-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 1px;
  transform: scaleY(0.5);
  background-color: rgba(0, 0, 0, 0.33);
}

.bottomBar-4 {
  margin: 0px;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  font-size: 0px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.bottomBar-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  height: 50px;
}

.bottomBar-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 26px;
  height: 26px;
}

.bottomBar-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.bottomBar-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  color: var(--boutton-background-color);
  font-size: 10px;
  line-height: normal;
  margin-top: 0px;
}

.bottomBar-9 {
  margin: 0px;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  font-size: 0px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.bottomBar-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  height: 50px;
}

.bottomBar-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 26px;
  height: 26px;
}

.bottomBar-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.bottomBar-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  color: rgb(153, 153, 153);
  font-size: 10px;
  line-height: normal;
  margin-top: 0px;
}

.bottomBar-14 {
  margin: 0px;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  font-size: 0px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.bottomBar-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  height: 50px;
}

.bottomBar-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 26px;
  height: 26px;
}

.bottomBar-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.bottomBar-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  color: rgb(153, 153, 153);
  font-size: 10px;
  line-height: normal;
  margin-top: 0px;
}

.bottomBar-19 {
  margin: 0px;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  font-size: 0px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.bottomBar-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  height: 50px;
}

.bottomBar-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 26px;
  height: 26px;
}

.bottomBar-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.bottomBar-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  color: rgb(153, 153, 153);
  font-size: 10px;
  line-height: normal;
  margin-top: 0px;
}

.bottomBar-24 {
  margin: 0px;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  font-size: 0px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.bottomBar-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  height: 50px;
}

.bottomBar-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 26px;
  height: 26px;
}

.bottomBar-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.bottomBar-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  color: rgb(153, 153, 153);
  font-size: 10px;
  line-height: normal;
  margin-top: 0px;
}

.bottomBar-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  margin-bottom: env(safe-area-inset-bottom);
  height: 50px;
}
